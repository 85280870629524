<template>
  <div class="mb-horarios__dia-hora text-xs uppercase tracking-wider leading-none font-bold flex items-center">
    <Icon name="mdi:clock-time-three-outline" width="16" height="16" class="mr-4" />
    {{ fecha }} {{ hora }}
  </div>
</template>

<script lang="ts" setup>
import { horaState } from "../store/hora";
import { onBeforeMount, onBeforeUnmount, computed } from "vue";

const horaBase = horaState();

let timeout: any;
const timer = () => {
  return setTimeout(() => {
    horaBase.updateHora();
    timeout = timer();
  }, 10e3);
};

onBeforeMount(() => {
  horaBase.updateHora();
  timeout = timer();
});

onBeforeUnmount(() => {
  clearTimeout(timeout);
});
const hora = computed(() => horaBase.hora);
const fecha = computed(() => horaBase.fecha);
</script>
