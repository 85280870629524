const switchTimes = (seconds = 0) => {
  let sufix: Intl.RelativeTimeFormatUnit = 'hours';
  let time = seconds / 3600;

  if (Math.abs(seconds) < 60) {
    sufix = 'seconds';
    time = seconds;
  } else if (Math.abs(seconds) < 3600) {
    sufix = 'minutes';
    time = seconds / 60;
  }

  return { sufix, time };
}

export const formatDate = (dateStart = new Date()) => {
  const dateNow = new Date().getTime();
  const deltaDate = (-1 * dateNow - (-1 * dateStart.getTime())) / 1e3;
  const { sufix, time } = switchTimes(deltaDate);
  
  const rtf = new Intl.RelativeTimeFormat('es-CL', { style: 'short', numeric: 'always' });
  return rtf.format(Math.ceil(time), sufix);
};