<template>
  <div
    v-if="horario.id"
    :class="[
      'mb-horario__container',
      horario.vigente && 'vigente',
      horario.primero && 'primero',
    ]"
  >
    <div class="mb-horario__container-hora">
      <div class="mb-horario__loop">
        <button class="flex items-center dark:text-gray-500 text-gray-500">
          <Icon name="ion:arrow-return-left" @click="toggleActivoHorario(horario.
            S
          )" />
        </button>
      </div>
      <div class="mb-horario__salida">
        <span>{{ horario.S }}</span>
      </div>
      <div class="mb-horario__llegada">
        <span>{{ horario.T }}</span>
      </div>
      <DelayHydration>
        <div class="mb-horario__tiempo">
          <em>{{ horario.rel }}</em>
          <span>{{ horario.V }}</span>
        </div>
      </DelayHydration>
    </div>
    <div v-if="isActivoHorario === parseInt(horario.S, 10)" class="mb-horario__container-retorno">
      <h3 class="font-semibold text-lg mb-4">Horarios Retorno</h3>
      <ul class="pl-28 pb-4" v-if="horariosRetorno.length">
        <li class="flex items-center">
          <div class="mb-salida flex-1">
            <span class="font-sans text-sm">{{ estacionLlegada }}</span>
          </div>
          <div class="mb-llegada flex-1">
            <span class="font-sans text-sm">{{ estacionSalida }}</span>
          </div>
          <div class="mb-horario__tiempo flex-[0_0_60px]"></div>
        </li>
        <!--TODO: Add row title to see which estación we're leaving from-->
        <li v-for="horarioRetorno in horariosRetorno">
          <div class="mb-horario__container-hora">
            <div class="mb-horario__salida return">
              <span>{{ horarioRetorno.S }}</span>
            </div>
            <div class="mb-horario__llegada return">
              <span>{{ horarioRetorno.T }}</span>
            </div>
            <DelayHydration>
              <div class="mb-horario__tiempo">
                <em>{{ horarioRetorno.rel }}</em>
                <span>{{ horarioRetorno.V.join(', ') }}</span>
              </div>
            </DelayHydration>
          </div>
        </li>
      </ul>
      <div v-else class="text-sm text-gray-500 italic">No hay horarios de retorno disponibles 😩</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Horario } from "@/interfaces";

const isActivoHorario = ref(0);
type HorarioSingle = Horario & {
  id: string;
  S2?: string | undefined;
  rel: string;
}
const props = defineProps<{
  horario: HorarioSingle;
  horariosVuelta: Map<string, HorarioSingle>;
  estacionSalida: string;
  estacionLlegada: string;
}>();

const toggleActivoHorario = (horaSalida: string) => {
  isActivoHorario.value = !isActivoHorario.value ? parseInt(horaSalida, 10) : 0;
};

const horariosRetorno = computed(() => {
  const horariosRetornoArray = Array.from(props.horariosVuelta.values());

  const indexHorarioRetornoSiguiente = horariosRetornoArray.findIndex((horarioRetorno) => horarioRetorno.S >= props.horario.T);

  return horariosRetornoArray.slice(indexHorarioRetornoSiguiente, indexHorarioRetornoSiguiente + 5).sort((a, b) => a.id > b.id ? -1 : 1);
});

</script>

<style lang="scss">
.mb-horario__container {
  @apply border-b border-grey-light;
  
  .mb-horario__container-hora {
    @apply flex py-8;
    
    .mb-horario__loop {
      flex: 0 0 30px;
      @apply items-center flex;
    }
  
    .mb-horario__salida,
    .mb-horario__llegada {
      flex: 1;
    }
    .mb-horario__tiempo {
      min-width: 60px;
      flex: 0 0 60px;
    }
  
    .mb-horario__salida,
    .mb-horario__llegada {
      @apply leading-none flex items-center;
  
      span {
        font-family: "Roboto Mono", monospace;
        @apply text-2xl block font-normal leading-none;
      }
    }
    .mb-horario__tiempo {
      @apply leading-none text-right flex flex-col justify-center;
  
      em {
        @apply text-grey-darker text-sm block mb-4;
      }
      span {
        @apply block text-xs;
      }
    }
  }
  &:not(.vigente) {
    @apply text-gray-400;
  }

  &.vigente {
    @apply text-gray-800;

    &.primero {
      .mb-horario__salida,
      .mb-horario__llegada {
        &:not(.return) {
          span {
            @apply text-orange;
          }
        }
      }
    }
  }
}
.dark-mode .mb-horario__container {
  @apply border-gray-700 text-gray-200;

  &:not(.vigente) {
    @apply text-gray-600;
  }

  .mb-horario__tiempo {
    em {
      @apply text-gray-300;
    }
  }
}
</style>
