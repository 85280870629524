import type { Coordinate, IEstacion } from '@/interfaces';
/**
 * OBTIENE LA DISTANCIA ENTRE 2 PUNTOS GEOGRAFICOS
 *
 * @param {Object} coor1 {lat,lon}
 * @param {Object} coor2 {lat, lon}
 * @returns {int} distance in meters
 */
function distance(coor1: Coordinate, coor2: Coordinate): number {
  if (!coor1.lat || !coor2.lat) return Infinity;

  const { lat: lat1, lng: lon1 } = coor1;
  const { lat: lat2, lng: lon2 } = coor2;

  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    c(lat1 * p) * c(lat2 * p) * ((1 - c((lon2 - lon1) * p)) / 2);

  const coor = 12742 * Math.asin(Math.sqrt(a)) * 1000;

  return parseInt(String(coor), 10);
}

/**
 * CALCULA LA DISTANCIA DE TODAS LAS ESTACIONES
 * A UN PUNTO DADO
 *
 * @export
 * @param {Object} coor {lat, lon}
 * @param {Array} listaDistancias
 * @returns
 */
export function calculateDistances(coor: Coordinate, listaDistancias: IEstacion[]): IEstacion[] {
  if (!coor.lat || !coor.lng) return [];

  const distancias = listaDistancias
    .map(estacion => {
      const distancia = distance(coor, { lat: estacion.lat, lng: estacion.lng });
      return Object.assign({}, estacion, { distancia });
    })
    .sort((a, b) => a.distancia - b.distancia);

  return distancias;
}

export function getEstacionByGeo(estaciones: IEstacion[] = []) {
  return (coor: Coordinate) => {
    const estacion = calculateDistances(coor, estaciones)[0];
    return estacion;
  }
}