import { defineStore } from 'pinia';

export interface IHoraState {
  date: Date,
  fecha: string;
  hora: string;
}

const getHora = (date = new Date()) =>
  date.toLocaleTimeString('es-CL', {
    hour12: false,
    minute: '2-digit',
    hour: '2-digit',
    timeZone: 'America/Santiago',
  });
const getFecha = (date = new Date()) =>
  date.toLocaleDateString('es-CL', {
    weekday: 'long',
    timeZone: 'America/Santiago',
  });

export const horaState = defineStore('hora', {
  state: (): IHoraState => ({
    date: new Date(),
    fecha: getFecha(),
    hora: getHora(),
  }),
  actions: {
    updateHora(date = new Date()) {
      this.date = date;
      this.hora = getHora(date);
      this.fecha = getFecha(date);
    },
  }
})